
import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component({
  name: 'dn-tab',
})
export default class Tab extends Vue {
  @Prop({ type: String, required: true }) title!: String;
  @Prop({ type: Boolean, required: false }) active!: boolean;
  @Prop({ type: Boolean, default: false }) renderAll!: boolean;

  public isActive: boolean = this.active;
}
