
import { Component, Vue, Emit } from 'nuxt-property-decorator';
import { LoginFormModel } from '~/models/forms/login-form';
import { Form } from '~/models/forms/reactive-form';

@Component({
  name: 'dn-login-form',
})
export default class LoginForm extends Vue {
  private form: Form<LoginFormModel> | null = null;

  created() {
    this.createForm();
  }

  async createForm() {
    const loginFields = new LoginFormModel();
    loginFields.email = null;
    loginFields.password = null;
    this.form = new Form(loginFields);
  }

  @Emit('submit')
  private onSubmit(): Form<LoginFormModel> | null {
    return this.form;
  }
}
