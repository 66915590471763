import {
  Required,
  Email,
  MaxLength,
  MinLength,
  NotEquals,
  IsPhoneNumber,
} from '~/models/forms/validator';
import { FIELD_VALIDATIONS } from '~/constants/field-validations';
import { i18n } from '~/plugins/i18n';
export class RegisterFormModel {
  @Required()
  name!: string | null;

  @Required()
  @Email()
  email!: string | null;

  @Required()
  @IsPhoneNumber()
  phone!: string | null;

  @Required()
  @MinLength(FIELD_VALIDATIONS.password.min)
  @MaxLength(FIELD_VALIDATIONS.password.max)
  password!: string | null;

  @NotEquals()
  regulationsAcceptance!: boolean | null;

  @NotEquals()
  privacyPolicy!: boolean | null;
}
