
import { Component, Vue, Emit } from 'nuxt-property-decorator';
import { RegisterFormModel } from '~/models/forms/register-form';
import { Form } from '~/models/forms/reactive-form';

@Component({
  name: 'dn-register-form',
})
export default class RegisterForm extends Vue {
  private form: Form<RegisterFormModel> | null = null;

  created() {
    this.createForm();
  }

  async createForm() {
    const registerFields = new RegisterFormModel();
    registerFields.name = null;
    registerFields.email = null;
    registerFields.password = null;
    registerFields.phone = null;
    registerFields.regulationsAcceptance = null;
    registerFields.privacyPolicy = null;
    this.form = new Form(registerFields);
  }

  @Emit('submit')
  private onSubmit(): Form<RegisterFormModel> | null {
    return this.form;
  }
}
