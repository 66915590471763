import { FIELD_VALIDATIONS } from '~/constants/field-validations';
import {
  Required,
  Email,
  MaxLength,
  MinLength,
} from '~/models/forms/validator';
export class LoginFormModel {
  @Required()
  @Email()
  email!: string | null;

  @Required()
  @MinLength(FIELD_VALIDATIONS.password.min)
  @MaxLength(FIELD_VALIDATIONS.password.max)
  password!: string | null;
}
