import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class TabsMixin extends Vue {
  @Prop({ type: String, default: '100%' }) width!: string;

  public selectedIndex: number = 0;
  private tabs: Vue[] = [];

  private created() {
    this.tabs = this.$children;

    const { tab: tabIndex } = this.$route.query;

    if (tabIndex) {
      this.selectTab(Number(tabIndex), true);
    }
  }

  private mounted() {
    this.selectTab(0, true);
  }

  private selectTab(index: number, disableEmit?: boolean): void {
    this.selectedIndex = index;

    if (!disableEmit) {
      this.$emit('tab-selected', index);
    }

    this.tabs.forEach((tab, i) => {
      tab.$data.isActive = index === i;
    });
  }
}
