export const FIELD_VALIDATIONS: { [key: string]: { [key: string]: any } } = {
  password: {
    min: 8,
    max: 255,
  },
  street: {
    min: 1,
    max: 32,
  },
  file: {
    mimeItems: [
      { mime: 'image/jpeg', ext: 'jpg' },
      { mime: 'application/pdf', ext: 'pdf' },
      { mime: 'image/png', ext: 'png' },
      { mime: 'text/plain', ext: 'txt' },
      { mime: 'application/msword', ext: 'doc' },
      {
        mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ext: 'docx',
      },
    ],
  },
};
