
import { Vue, Component } from 'nuxt-property-decorator';

@Component({
  name: 'dn-login-page',
})
export default class LoginPage extends Vue {
  private login(): void {
    // todo when api ready
    console.log('login');
  }

  private register(): void {
    // todo when api ready
    console.log('register');
  }
}
